var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ls-device-category-edit"},[_c('div',{staticClass:"ls-card device-category-edit__header"},[_c('el-page-header',{attrs:{"content":_vm.id ? '编辑设备类型' : '新增设备类型'},on:{"back":function($event){return _vm.$router.go(-1)}}})],1),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"120px","size":"small","rules":_vm.rules}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"ls-card device-category-edit__base m-t-16"},[_c('div',{staticClass:"nr weight-600 m-b-20"},[_vm._v("基础信息")]),_c('el-form-item',{attrs:{"label":"类型名称","prop":"name","required":""}},[_c('el-input',{staticClass:"ls-input",attrs:{"maxlength":"8","show-word-limit":"","placeholder":"请输入类型名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"父级类型","required":""}},[_c('el-radio-group',{staticClass:"m-r-16",model:{value:(_vm.hasPid),callback:function ($$v) {_vm.hasPid=$$v},expression:"hasPid"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("无父级类型")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("有父级类型")])],1),_c('el-alert',{staticClass:"ls-tips",attrs:{"title":"选择无上级类型，则表明此类型为一级类型；选择有上级类型，则表明此类型为选中类型的子类型","type":"warning","closable":false}})],1),(_vm.hasPid == 1)?_c('el-form-item',{attrs:{"label":"","required":""}},[_c('el-cascader',{attrs:{"options":_vm.deviceCategoryList,"props":{
            checkStrictly: true,
            value: 'id',
            label: 'name',
            children: 'children',
            emitPath: false,
          },"clearable":""},model:{value:(_vm.form.pid),callback:function ($$v) {_vm.$set(_vm.form, "pid", $$v)},expression:"form.pid"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"排序"}},[_c('el-input',{staticStyle:{"width":"220px"},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}}),_c('div',{staticClass:"muted xs"},[_vm._v("排序值必须为整数；数值越小，越靠前")])],1),_c('el-form-item',{attrs:{"label":"是否显示"}},[_c('el-switch',{attrs:{"active-value":0,"inactive-value":1},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)],1)]),_c('div',{staticClass:"device-category-edit__footer bg-white ls-fixed-footer"},[_c('div',{staticClass:"btns row-center flex",staticStyle:{"height":"100%"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("取消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.handleSave}},[_vm._v("保存")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }